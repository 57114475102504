export const gnbData = [
    { title: '환경설정', link: '/boffice/Preferences/Management' },
    { title: '회원관리', link: '/boffice/Member/Account' },
    { title: '컨텐츠 관리', link: '/boffice/Contents/Management' },
    { title: '게시판 관리', link: '/boffice/Boards/Community' },
    { title: '결제/환불 관리', link: '/boffice/Payments/Pay' },
    { title: '예약 관리', link: '/boffice/Reservation/Management' },
    { title: '정산 관리', link: '/boffice/Profits/List' },
];
export const subPreferances = [
    {
        title: '팝업 관리',
        link: '/boffice/Preferences/Management',
        depth: 'depth1',
    },
    {
        title: '배너 관리',
        link: '/boffice/Preferences/Banner',
        depth: 'depth1',
    },
    {
        title: '추천 검색어 관리',
        link: '/boffice/Preferences/SearchTerm',
        depth: 'depth1',
    },
    {
        title: 'PUSH 알림',
        link: '/boffice/Preferences/PushNotification',
        depth: 'depth1',
    },
    {
        title: '증상 설문조사',
        link: '/boffice/Preferences/SymptomSurvey',
        depth: 'depth1',
    },
    {
        title: '약관 관리',
        link: '/boffice/Preferences/TermsCondition',
        depth: 'depth1',
    },
    {
        title: '쿠폰 관리',
        link: '/boffice/Preferences/Coupon',
        depth: 'depth1',
    },
    {
        title: '포인트 관리',
        link: '/boffice/Preferences/Points',
        depth: 'depth1',
    },
    {
        title: '전문가 정보 카테고리',
        link: '/boffice/Preferences/ExpertInformation',
        depth: 'depth1',
    },
    {
        title: '환자 병명 카테고리',
        link: '/boffice/Preferences/PatientDisease',
        depth: 'depth1',
    },
    {
        title: '연령 카테고리',
        link: '/boffice/Preferences/AgeCategory',
        depth: 'depth1',
    },
];

export const subMember = [
    {
        title: '관리자 계정 관리',
        link: '/boffice/Member/Account',
        depth: 'depth1',
    },
    {
        title: '사이트 회원 계정 관리',
        link: '/boffice/Member/SiteMember',
        depth: 'depth1',
    },
    {
        title: '회원 정지',
        link: '/boffice/Member/Suspension',
        depth: 'depth1',
    },
    {
        title: '전문가 회원 승인',
        link: '/boffice/Member/Approval',
        depth: 'depth1',
    },
];

export const subBoards = [
    {
        title: '커뮤니티',
        link: '/boffice/Boards/Community',
        depth: 'depth1',
    },
    {
        title: '공지사항',
        link: '/boffice/Boards/Notice',
        depth: 'depth1',
    },
    {
        title: 'FAQ',
        link: '/boffice/Boards/Faq',
        depth: 'depth1',
    },
    {
        title: '1:1 문의',
        link: '/boffice/Boards/Inquiry',
        depth: 'depth1',
    },
];

export const subContents = [
    {
        title: '컨텐츠 관리',
        link: '/boffice/Contents/Management',
        depth: 'depth1',
    },
    {
        title: '컨텐츠 제안 내역',
        link: '/boffice/Contents/History',
        depth: 'depth1',
    },
];

export const subPayments = [
    {
        title: '결제내역',
        link: '/boffice/Payments/Pay',
        depth: 'depth1',
    },
    {
        title: '환불내역',
        link: '/boffice/Payments/Refund',
        depth: 'depth1',
    },
];

export const subReservation = [
    {
        title: '예약내역',
        link: '/boffice/Reservation/Management',
        depth: 'depth1',
    },
];

export const subProfits = [
    {
        title: '국가별 정산 기준 관리',
        link: '/boffice/Profits/List',
        depth: 'depth1',
    },
    {
        title: '정산 수익 관리',
        link: '/boffice/Profits/SettlementProfitManagement',
        depth: 'depth1',
    },
];
export const SESSION_STORAGE_KEY = 'admin-token';
